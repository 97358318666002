<template>
	<div>
	<div class="row">
		<div class="col-md-12">
			<h2>{{ $t('dashboard.todo') }}</h2>
		</div>
	</div>
	<div class="row">
		<div class="col-md-4" v-if="pastSchedule.length">
			<div class="thumbnail">
				<div class="caption">
					<div class="well">
						<h4>{{ $t('dashboard.schedule.title') }}</h4>
					</div>
					<p>{{ $t('dashboard.schedule.card1') }} <b>{{ pastSchedule.length }}</b> {{ $t('dashboard.schedule.card2') }}</p>
					<router-link to="schedule">
						<button type="button" class="btn btn-primary full-width">Go to schedule</button>
					</router-link>
				</div>
			</div>
		</div>
		<div class="col-md-4" v-if="amountOfInvoices.draft">
			<div class="thumbnail">
				<div class="caption">
					<div class="well">
						<h4>{{ $t('dashboard.invoice.finalize.title') }}</h4>
					</div>    
					<p>{{ $t('dashboard.invoice.finalize.card1') }} <b>{{ amountOfInvoices.draft }}</b> {{ $t('dashboard.invoice.finalize.card2') }}</p>
					<router-link to="invoices">
						<button type="button" class="btn btn-primary full-width">Go to invoices</button>
					</router-link>
				</div>
			</div>
		</div>
		<div class="col-md-4" v-if="amountOfInvoices.approve">
			<div class="thumbnail">
				<div class="caption">
					<div class="well">
						<h4>{{ $t('dashboard.invoice.approve.title') }}</h4>
					</div>
					<p>{{ $t('dashboard.invoice.approve.card1') }} <b>{{ amountOfInvoices.approve }}</b> {{ $t('dashboard.invoice.approve.card2') }}</p>
					<router-link to="invoices">
						<button type="button" class="btn btn-primary full-width">Go to invoices</button>
					</router-link>
				</div>
			</div>
		</div>
		<!-- Invoices Status 3 Comment -->
		<div class="col-md-4" v-if="amountOfInvoices.comments">
			<div class="thumbnail">
				<div class="caption">
					<div class="well">
						<h4>Invoices with comments</h4>
					</div>
					<p>There are <b>{{ amountOfInvoices.comments }}</b> invoices with comments</p>
					<router-link to="invoices">
						<button type="button" class="btn btn-primary full-width">Go to invoices</button>
					</router-link>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<h2>{{ $t('dashboard.messages') }}</h2>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<h2>{{ $t('dashboard.statistics') }}</h2>
			<div v-for="invoiceType in invoiceTypes" :key="invoiceType">
				<a>
					<span v-if="invoiceType == 'total'">
						<b>{{ $t('dashboard.invoice.labels.' + invoiceType) }}</b>
					</span>
					<span v-else>
						{{ $t('dashboard.invoice.labels.' + invoiceType) }}
					</span>
					<span class="badge right">{{ amountOfInvoices[invoiceType] }}</span>
				</a>
				<br />
			</div>
		</div>
	</div>
	<hr>
	<div class="row">
		<div class="col-md-12">
			<div v-for="scheduleType in scheduleTypes" :key="scheduleType">
				<a>
					<span v-if="scheduleType == 'total'">
						<b>{{ $t('dashboard.schedule.labels.' + scheduleType) }}</b>
					</span>
					<span v-else>
						{{ $t('dashboard.schedule.labels.' + scheduleType) }}
					</span>
					<span class="badge right">{{ amountOfSchedules[scheduleType] }}</span>
				</a>
				<br />
			</div>
		</div>
	</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	data () {
		return {
			pastSchedule: [],
			upcomingSchedule: [],
			invoices: [],
			amountOfInvoices: {
				'draft': 0,
				'comments': 0,
				'approve': 0,
				'other': 0,
				'total': 0,
			},
			invoiceTypes: [
				'draft',
				'comments',
				'approve',
				'other',
				'total'
			],
			amountOfSchedules: {
				'upcomming': 0,
				'past': 0,
				'total': 0,
			},
			scheduleTypes: [
				'upcomming',
				'past',
				'total'
			]
		}
	},
	methods: {


	},
	computed: {
		...mapGetters({
			currentUser: 'currentUser',
		})
	},
	created () {
		let matches = [];
		if (this.$route.hash !== undefined && typeof this.$route.hash == 'string' && (matches = this.$route.hash.match(/^#\/([A-Za-z0-9_/]+)/))) {
			if (matches[1] !== undefined && matches[1]) {
				this.$router.push('/page/app/' + matches[1]);
			}
		}
	},
	mounted () {
		// mounted is called when component is mounted
		if (this.currentUser.user_role_id > 1) {
			this.req('POST', '/schedule/getPast').then((response) => {
				if (response.status) {
					this.pastSchedule = response.data;
					this.amountOfSchedules.past += response.data.length;
					this.amountOfSchedules.total += response.data.length;
				}
			});
		}
		this.req('POST', '/schedule/getUpcoming').then((response) => {
			if (response.status) {
				this.upcomingSchedule = response.data;
				this.amountOfSchedules.upcomming += response.data.length;
				this.amountOfSchedules.total += response.data.length;
			}
		});
		this.req('POST', '/invoice/get').then((response) => {
			if (response.status) {
				this.invoices = response.data;
				this.amountOfInvoices.total = response.data.length;
				this.invoices.forEach((invoice) => {
					switch (invoice.status_id) {
						case "1":
							this.amountOfInvoices.draft += 1;
							break;
						case "2":
							this.amountOfInvoices.approve += 1;
							break;
						case "3":
							this.amountOfInvoices.comments += 1;
							break;
						default:
							this.amountOfInvoices.other += 1;
					}
				})
			}
		});

	}
}
</script>
